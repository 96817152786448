<template>
  <div class="page_">
    <a-spin class="loading_" size="large" :spinning="spinning"></a-spin>
    <a-radio-group
      @change="fetchData"
      v-model="couponsType"
      class="c_form_item_ no_top_mrg_"
      size="large"
    >
      <a-radio-button value="">{{
        $t('coupons.labels[0]') || '全部'
      }}</a-radio-button>
      <a-radio-button value="Y">{{
        $t('coupons.labels[1]') || '生效中'
      }}</a-radio-button>
      <a-radio-button value="N">{{
        $t('coupons.labels[2]') || '已失效'
      }}</a-radio-button>
    </a-radio-group>
    <a-form :form="searchForm" layout="horizontal">
      <a-row class="c_form_item_ h58_" :gutter="12">
        <!-- <a-col :span="6" class="marLeft_20">
					<a-form-item :label="$t('coupons.labels[3]') || '卡名称'">
						<a-input v-decorator="['title']" :placeholder="$t('tips[10]')"></a-input>
					</a-form-item>
				</a-col> -->
        <a-col :span="6" class="marLeft_20">
          <a-form-item :label="$t('coupons.labels[4]') || '卡号'">
            <a-input
              v-decorator="[
                'couponsNo',
                {
                  rules: [{ required: true, message: '卡号!' }]
                }
              ]"
              :placeholder="$t('tips[10]')"
            ></a-input>
          </a-form-item>
        </a-col>
        <!-- <a-col :span="7">
					<a-form-item :label="$t('coupons.labels[5]') || '有效期'">
						<a-range-picker
							v-decorator="['rangeDate', { initialValue: undefined }]"
							:placeholder="[$t('tips[27]') || '开始时间', $t('tips[28]') || '结束时间']"
							valueFormat="YYYY-MM-DD"
						></a-range-picker>
					</a-form-item>
				</a-col> -->
        <a-col :span="2" class="marLeft_20">
          <a-button type="primary" @click="fetchData">{{
            $t('btns[0]') || '查询'
          }}</a-button>
        </a-col>
        <a-col :span="2">
          <a-button type="primary" ghost @click="resetConditions">{{
            $t('btns[18]') || '重置'
          }}</a-button>
        </a-col>
      </a-row>
    </a-form>
    <a-empty class="no-data" v-if="!list.length" :image="simpleImage" />
    <a-row v-else class="container" type="flex">
      <a-col
        :xxl="4"
        :xl="6"
        :lg="8"
        :md="12"
        v-for="(item, i) in list"
        :key="item.id"
      >
        <div
          class="coupon-item"
          :class="{
            'history-item': item.activeFlagDesc === '已失效',
            'no-margin': (i + 1) % 6 === 0
          }"
        >
          <div class="top-box">{{ item.title }}</div>
          <div class="middle-box">
            <span class="amount"
              >{{ item.couponsLimit
              }}<span class="unit">{{
                item.type === 'A' ? '元' : '折'
              }}</span></span
            >
            <span class="desc">{{ item.condition }}</span>
            <span class="date">{{
              `${moment(item.startDate).format('YYYY.MM.DD')} - ${moment(
                item.endDate
              ).format('YYYY.MM.DD')}`
            }}</span>
          </div>
          <div class="bottom-box">
            <span class="detail-btn" @click="showDetails(item)">{{
              $t('coupons.btns[0]') || '查看详情'
            }}</span>
            <span
              class="use-btn"
              @click="use(item)"
              v-if="item.activeFlagDesc !== '已失效'"
              >{{ $t('coupons.btns[1]') || '立即使用' }}</span
            >
          </div>
        </div></a-col
      >
    </a-row>
    <!-- 优惠卡弹窗 -->
    <a-modal
      :header="null"
      :closable="false"
      width="345px"
      wrapClassName="code-modal"
      v-model="codeShow"
    >
      <div class="content">
        <div class="title">{{ detailData.title }}</div>
        <div class="qrCode"><img :src="codeUrl" alt="no" /></div>
        <div class="coupon-no">卡号：{{ detailData.couponsNo }}</div>
        <a-button class="confirm-btn" type="primary" @click="confirm">{{
          $t('coupons.btns[2]' || '确认核销')
        }}</a-button>
      </div>
      <template #footer>
        <div class="footer">
          <div class="left-box">有效次数：{{ detailData.activeCountDesc }}</div>
          <div class="right-box">
            已使用：{{ detailData.verifyCount || 0 }}次
          </div>
        </div>
      </template>
    </a-modal>
    <!-- 核销成功弹窗 -->
    <a-modal
      :header="null"
      :footer="null"
      :closable="false"
      width="270px"
      wrapClassName="result-modal"
      v-model="resultShow"
    >
      <div class="content">
        <img class="result-img" src="../assets/medilink/coupons/success.png" />
        <div class="result-text">核销成功！</div>
      </div>
      <div class="close-btn" @click="resultShow = false">
        <img src="../assets/medilink/coupons/close.png" />
      </div>
    </a-modal>
    <!-- 卡详情弹窗 -->
    <a-modal
      :header="null"
      :footer="null"
      :closable="false"
      width="540px"
      wrapClassName="details-modal"
      v-model="detailShow"
    >
      <div class="content">
        <div class="title">卡详情</div>
        <div class="section">
          <div class="section-title"><i />使用条件</div>
          <div class="section-text">{{ detailData.useLimit }}</div>
        </div>
        <div class="section">
          <div class="section-title"><i />优惠卡介绍</div>
          <div class="section-text">{{ detailData.description }}</div>
        </div>
      </div>
      <div class="close-btn" @click="detailShow = false">
        <img src="../assets/medilink/coupons/close.png" />
      </div>
    </a-modal>
  </div>
</template>
<script>
import { Empty } from 'ant-design-vue'
import moment from 'moment'
import commonMixin from '@/mixins/common'
export default {
  data() {
    return {
      // 查询卡类型
      couponsType: '',
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      list: [],
      searchForm: this.$form.createForm(this),
      spinning: false,
      // 卡详情
      detailData: {},
      // 优惠卡弹窗
      codeShow: false,
      // 核销结果弹窗
      resultShow: false,
      // 卡详情弹窗
      detailShow: false,
      // 优惠卡二维码
      codeUrl: ''
    }
  },
  components: {},
  mixins: [commonMixin],
  methods: {
    moment,
    // 重置
    resetConditions() {
      this.couponsType = ''
      this.searchForm.resetFields()
      this.fetchData()
    },
    // 查询卡信息
    fetchData() {
      this.searchForm.validateFields((err, options) => {
        if (!err) {
          this.spinning = true
          let params = {
            activeFlag: this.couponsType || '',
            title: options.title || '',
            couponsNo: options.couponsNo || '',
            startDate: options.rangeDate ? options.rangeDate[0] : '',
            endDate: options.rangeDate ? options.rangeDate[1] : ''
          }
          this.$apis
            .getCouponsList(params)
            .then(res => {
              const data = res.data
              if (data.code === 200) {
                this.list = data.data || []
                if (!this.list.length) {
                  this.$message.warning('搜索无结果，请检查卡号是否正确')
                }
              } else {
                this.$message.error(data.msg || '查询失败')
              }
            })
            .finally(() => {
              this.spinning = false
            })
        }
      })
    },
    // 查看卡详情
    showDetails(item) {
      this.detailData = item
      this.detailShow = true
    },
    // 立即使用
    use(item) {
      this.detailData = item
      this.codeShow = true
      import('jr-qrcode').then(jrQrcode => {
        // 模块方法调用，传入字符串，获取 base64 编码的图片 URL
        var imgBase64 = jrQrcode.getQrBase64(item.couponsNo || '123123')
        this.codeUrl = imgBase64
      })
    },
    // 核销卡
    confirm() {
      this.spinning = true
      let params = {
        id: this.detailData.id
      }
      this.$apis
        .verifyConpons(params)
        .then(res => {
          const data = res.data
          if (data.code === 200) {
            this.codeShow = false
            this.resultShow = true
            this.fetchData()
          } else {
            this.$message.error(data.msg || '核销失败')
          }
        })
        .finally(() => {
          this.spinning = false
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.page_ {
  .no-data {
    margin-top: 10%;
  }
  .container {
    width: 100%;
    height: calc(100vh - 235px);
    overflow-y: auto;
    padding: 20px;
    .coupon-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 225px;
      height: 278px;
      margin-bottom: 30px;
      background: url(../assets/medilink/coupons/coupons-bg-active.png)
        no-repeat;
      background-size: cover;
      &.no-margin {
        margin-right: 0;
      }
      &.history-item {
        background: url(../assets/medilink/coupons/coupons-bg.png) no-repeat;
        background-size: cover;
        .top-box {
          color: #5c5c5c;
        }
        .middle-box {
          .amount {
            color: #999999;
            .unit {
              color: #4f3a1d;
            }
          }
          .desc {
            color: #999999;
            width: 100%;
            padding: 0 10rpx;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .bottom-box {
          justify-content: center;
          .detail-btn {
            color: #999999;
            border-color: #999999;
          }
        }
      }
      & > div {
        display: flex;
        align-items: center;
      }
      .top-box {
        max-width: 146px;
        height: 94px;
        line-height: 18px;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
        color: #4f3a1d;
      }
      .middle-box {
        flex: 1;
        flex-direction: column;
        justify-content: center;
        .amount {
          font-size: 26px;
          font-weight: bold;
          color: #ff522c;
          line-height: 30px;
          .unit {
            font-size: 15px;
            color: #333333;
            line-height: 18px;
          }
        }
        .desc {
          color: #333333;
          margin: 4px 0 8px;
        }
        .date {
          font-size: 13px;
          color: #999999;
          line-height: 15px;
        }
      }
      .bottom-box {
        width: 100%;
        padding: 20px;
        height: 73px;
        justify-content: space-between;
        span {
          width: 75px;
          height: 30px;
          line-height: 30px;
          font-size: 13px;
          border-radius: 15px;
          border: 1px solid #b5693b;
          color: #b5693b;
          text-align: center;
          cursor: pointer;
          &.use-btn {
            color: #4f3a1d;
            border: none;
            background: linear-gradient(180deg, #efba99 0%, #fcdfc8 100%);
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.code-modal {
  .ant-modal-content {
    text-align: center;
    border-radius: 9px;
    .content {
      .title {
        font-size: 15px;
        font-weight: bold;
        color: #333333;
      }
      .qrCode {
        margin: 24px 0 15px;
        img {
          width: 138px;
          height: 138px;
        }
      }
      .coupon-no {
        font-size: 12px;
        color: #333333;
        margin-bottom: 24px;
      }
      .confirm-btn {
        width: 165px;
        height: 30px;
        border-radius: 3px;
      }
    }
    .footer {
      display: flex;
      justify-content: space-between;
      padding: 0 38px;
      text-align: left;
      div {
        color: #333333;
        font-size: 12px;
        &.left-box {
          width: 50%;
          border-right: 1px solid #e8e8e8;
        }
      }
    }
  }
}
.result-modal {
  padding: 57px 0;
  .ant-modal-content {
    text-align: center;
    border-radius: 9px;
    position: relative;
    .content {
      .result-img {
        width: 70px;
        height: 70px;
        margin-bottom: 21px;
      }
      .result-text {
        font-size: 15px;
        font-weight: bold;
        color: #333333;
      }
    }
    .close-btn {
      position: absolute;
      bottom: -48px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      img {
        width: 36px;
        height: 36px;
      }
    }
  }
}
.details-modal {
  padding: 15px 15px 23px;
  .ant-modal-content {
    text-align: center;
    border-radius: 9px;
    position: relative;
    .content {
      .title {
        margin-bottom: 11px;
      }
      .section {
        padding: 13px 0 13px 9px;
        &:first-child {
          margin-bottom: 15px;
        }
        .section-title {
          font-size: 12px;
          font-weight: bold;
          color: #333333;
          padding-bottom: 10px;
          border-bottom: 1px solid #e8e8e8;
          display: flex;
          align-items: center;
          i {
            display: block;
            width: 3px;
            height: 12px;
            background: #00ac51;
            border-radius: 2px;
            margin-right: 6px;
          }
        }
        .section-text {
          font-size: 11px;
          line-height: 15px;
          color: #333333;
          padding-top: 13px;
          text-align: left;
        }
      }
    }
    .close-btn {
      position: absolute;
      bottom: -48px;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      img {
        width: 36px;
        height: 36px;
      }
    }
  }
}
</style>
